import styled, { css } from "styled-components";
import { variant, border, minHeight, gridArea } from "styled-system";

import type { FlexProps } from "Shared/flexbox";
import Flex from "Shared/flexbox";
import theme from "Shared/theme";
import shouldNotForwardProps from "Shared/helpers/shouldNotForwardProps";

type CardPrimitiveProps = FlexProps & {
  $hasHoverEffect?: boolean;
  $shadow: boolean;
};

const hoverEffect = css`
  &:hover,
  &:focus {
    background-color: ${theme.colors.grey50};
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &:active {
    background-color: ${theme.colors.grey200};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const CardPrimitive = styled(Flex).withConfig(
  shouldNotForwardProps(["minHeight"]),
)<CardPrimitiveProps>`
  box-shadow: ${({ $shadow }) => ($shadow ? theme.shadows.medium : "")};
  position: relative;
  ${border}
  ${gridArea}
  ${variant({
    key: "cards",
  })}
  // transition: height 0.2 ease-in-out allow-discrete;
  ${minHeight} // overflow: hidden;
  ${({ $hasHoverEffect }) => $hasHoverEffect && hoverEffect}
`;

export default CardPrimitive;
