import axios from "axios";
import initXSRFToken from "./initXSRFToken";

export type HRlabIndexResponse<T> = {
  [x: string]: any;
  data: T[];
  page_index: number;
  total_pages: number;
  page_size: number;
  total_items_count: number;
};

export type HRlabStatusResponse = { status: string };

export const LOCALE_PARAM = ":locale";

const BASE_URL = `${process.env.REACT_APP_API_ROOT_URL}/${LOCALE_PARAM}`;

const api = axios.create({
  baseURL: BASE_URL,
  maxRedirects: 0,
  withCredentials: true,
  validateStatus: (status: number): boolean => {
    return (status >= 200 && status < 300) || status === 302;
  },
  headers: {
    "x-requested-with": "XMLHttpRequest",
  },
});

initXSRFToken();

// TODO: Error can only be thrown explicitly like this, which is weird.
// Normally Axios will throw an error upon any non-200 request.
// Need to take a look at this.
// api.interceptors.response.use(
//   (f) => f,
//   (error) => {
//     if (error?.response?.status === 401) {
//       const prefixLength = (process.env.REACT_APP_ROUTE_PREFIX || "").length;
//       const currentPath = window.location.pathname.slice(
//         1 + (prefixLength ? prefixLength : 0)
//       );
//       const addRedirect = currentPath?.length && !currentPath.includes("auth/");
//       let redirectUrl = `${process.env.REACT_APP_ROUTE_PREFIX}/auth/login`;
//       if (addRedirect) redirectUrl += `?redirect=${currentPath}`;
//       window.location.assign(redirectUrl);
//     }
//     throw Promise.reject(error);
//   }
// );

export default api;
