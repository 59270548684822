import React from "react";
import styled from "styled-components";

import SharedButton from "Shared/Button";
import { Flex } from "Shared/flexbox";
import i18n from "Root/initScripts/i18n";
import type { IconType } from "Shared/Icon/Icon";
import type { SpaceProps } from "styled-system";

type ModalFooterProps = SpaceProps & {
  submitLabel?: string;
  cancelLabel?: string;
  submitIcon?: IconType;
  cancelIcon?: IconType;
  hideCancelButton?: boolean;
  isSubmitting?: boolean;
  isFormDisabled?: boolean;
  // TODO: comment he even types back in and check all usages of ModalFooter
  onCancel?: (/*e?: React.MouseEvent*/) => void;
  onSubmit?: (/*e?: React.MouseEvent*/) => void;
};

const Button = styled(SharedButton)`
  border-radius: 0;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: none;
`;

const ModalFooter: React.FC<ModalFooterProps> = ({
  submitLabel = i18n.t("shared.save"),
  cancelLabel = i18n.t("shared.cancel"),
  submitIcon = "save",
  cancelIcon = "close",
  hideCancelButton = false,
  isSubmitting = false,
  isFormDisabled = false,
  onSubmit,
  onCancel,
  ...props
}) => {
  return (
    <Flex {...props}>
      {!hideCancelButton && (
        <Button
          width="50%"
          onClick={onCancel}
          variant="light"
          icon={cancelIcon}
          label={cancelLabel}
        />
      )}
      <Button
        type="submit"
        width={hideCancelButton ? "100%" : "50%"}
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={isFormDisabled}
        icon={submitIcon}
        label={submitLabel}
      />
    </Flex>
  );
};

export default ModalFooter;
