import type { SupportedLocale } from "types";

export const getPrivacyStatementURL = (locale: SupportedLocale): string => {
  if (locale === "de") {
    return `https://app.hrlab.de/hrlab_pdfs/HRlab_Datenschutzerklaerung.pdf`;
  } else {
    return `https://app.hrlab.de/hrlab_pdfs/HRlab_privacy_statement.pdf`;
  }
};

export const getTermsOfUseURL = (): string => {
  return `https://app.hrlab.de/hrlab_pdfs/HRlab_Nutzungsbedingungen.pdf`;
};

export const getIntroToHRlab = (locale: SupportedLocale): string => {
  if (locale === "de") {
    return `https://hrlabtridion.atlassian.net/l/cp/01gAgbLc`;
  } else {
    return `https://hrlabtridion.atlassian.net/l/cp/01gAgbLc`;
  }
};
