import { useState } from "react";

type LocalStorageValue = boolean | number | string;

type LocalStorage = [
  value: LocalStorageValue,
  wrappedSetValue: (
    _value: LocalStorageValue | ((value: LocalStorageValue) => void),
  ) => void,
];

const useLocalStorage = (
  key: string,
  initialValue: LocalStorageValue,
): LocalStorage => {
  const [value, setValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  const wrappedSetValue = (
    _value: LocalStorageValue | ((value: LocalStorageValue) => void),
  ) => {
    const newValue = typeof _value === "function" ? _value(value) : _value;
    setValue(newValue);
    window.localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, wrappedSetValue];
};

export default useLocalStorage;
