import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.maxRedirects = 0;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) return Promise.reject(error);
  },
);
