import React from "react";
import { createRoot } from "react-dom/client";

import App from "./Root";

window.addEventListener('vite:preloadError', (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.reload();
});

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(<App />);
