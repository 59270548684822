import type React from "react";

type WrapperProps = {
  condition: boolean;
  children: React.ReactElement;
  wrapper: (children: React.ReactElement) => JSX.Element;
};

const Wrapper: React.FC<WrapperProps> = ({ condition, children, wrapper }) =>
  condition ? wrapper(children) : children;

export default Wrapper;
