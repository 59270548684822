import React from "react";
import { useTranslation } from "react-i18next";

import Modal, { useModal } from "Shared/Modal";
import ModalBody from "./ModalBody";

const DownloadMobileAppModal: React.FC = () => {
  const { t } = useTranslation();
  const [modalProps, { openModal, closeModal }] = useModal();

  const isAndroid = React.useMemo(() => {
    return /Android/i.test(navigator.userAgent);
  }, []);

  const isIOS = React.useMemo(() => {
    return /iPhone|iPad/i.test(navigator.userAgent);
  }, []);

  const shouldShowModal = React.useMemo(() => {
    return (isAndroid || isIOS) && !modalProps.isOpen;
  }, [isAndroid, isIOS, modalProps.isOpen]);

  const handleContinueClick = React.useCallback(() => {
    localStorage.setItem("isWillingToUseBrowser", JSON.stringify(true));
    closeModal();
  }, [closeModal]);

  React.useEffect(() => {
    if (shouldShowModal) {
      openModal({
        title: t("download-mobile-app-modal.title"),
        width: "sm",
        closeCallback: handleContinueClick,
        body: <ModalBody onContinueClick={handleContinueClick} />,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Modal {...modalProps} />;
};

export default DownloadMobileAppModal;
