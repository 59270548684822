import React from "react";
import { format, isValid, parse } from "date-fns";
import i18n from "i18next";

/**
 * @module Shared/helpers/socialSecrurityUtils
 *
 * helper functions for handling german social security numbers
 */

export type SsnMetaData = {
  gender: "M" | "W" | "D" | "X";
  lastName?: string;
};

export const VALID_INSURANCE_NUMBERS = [
  "02",
  "03",
  "04",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "23",
  "24",
  "25",
  "26",
  "28",
  "29",
  "38",
  "39",
  "40",
  "42",
  "43",
  "44",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "63",
  "64",
  "65",
  "66",
  "68",
  "69",
  "78",
  "79",
  "80",
  "81",
  "82",
  "89",
];

export enum SsnFragment {
  pensionInsuranceNumber = "pension insurance number",
  birthDateNumbers = "birthdate",
  lastNameFirstLetter = "last name's first letter",
  genderNumbers = "gender",
  checkNumber = "check number",
}

const alphabet = "abcdefghijklmnopqrstuvwxyz";
const weightArray = [2, 1, 2, 5, 7, 1, 2, 1, 2, 1, 2, 1];

const numToDigitsArray = (num: number): number[] => {
  return num.toString().split("").map(Number);
};

const getLetterAlphabetPos = (letter: string): number => {
  let char = letter.toLowerCase().slice(0, 1);
  const index = alphabet.indexOf(char);
  if (index === -1) throw new Error("argument was not a valid letter");
  return index + 1;
};

/**
 * convert a date or date string to format ddMMyy. This format is needded for representation in a social security number
 * @example
 * // returns 010187
 * formatSSN("1987-01-01");
 * @param {string | Date} date date or valid date string
 * @returns {string} date representation in format ddMMyy
 */

export const formatSSN = (date: string | Date): string => {
  const newDate = new Date(date);
  let result = "";
  if (isValid(newDate)) {
    result = format(newDate, "ddMMyy");
  }
  return result;
};

/**
 * convert a date or date string to format ddMMyy. This format is needded for representation in a social security number
 * @example
 * // returns 3
 * calcCheckNumber("33110782D49X"); //X is the unknown check number
 * @param {string} ssn a social security number
 * @returns {number} returns the check number (1 digit)
 */

export const calcCheckNumber = (socialSecurityNumber: string): number => {
  const ssnAsArray: number[] = Array.from(
    socialSecurityNumber.replace(/\s/g, ""),
  )
    .map((char: string, i: number) => {
      if (i === 8) {
        const pos = getLetterAlphabetPos(char);

        return pos <= 9 ? [0, pos] : numToDigitsArray(pos);
      } else return Number(char);
    })
    .flat();
  if (ssnAsArray.length === 12)
    throw new Error("argument was not a valid social security number");
  const ssnAsWeightedArray = ssnAsArray
    .slice(0, 12)
    .map((num: number, i: number) => num * weightArray[i]);
  const checksum = ssnAsWeightedArray
    .map(numToDigitsArray)
    .flat()
    .reduce((sum, num) => sum + num, 0);
  return checksum % 10;
};

/**
 * extracts a given fragment from a social security number
 * @example
 * // returns 110782
 * extractFromSSN("33110782D493", SsnFragment.birthDateNumbers);
 * @param {string} ssn the social security number
 * @param {string} fragment the fragment that should be extracted form the social security number
 * @returns {string} the extracted fragment of the social security number
 */

export const extractFromSSN = (ssn: string, fragment: SsnFragment): string => {
  const preparedSSN = ssn.replace(/\s/g, "");

  switch (fragment) {
    case SsnFragment.pensionInsuranceNumber:
      return preparedSSN.slice(0, 2);
    case SsnFragment.birthDateNumbers:
      return preparedSSN.slice(2, 8);
    case SsnFragment.lastNameFirstLetter:
      return preparedSSN.slice(8, 9);
    case SsnFragment.genderNumbers:
      return preparedSSN.slice(9, 11);
    case SsnFragment.checkNumber:
      return preparedSSN.slice(11, 12);
    default:
      const unhandledValue: never = fragment;
      throw new Error(
        `social security number: unknown entity "${unhandledValue}"`,
      );
  }
};

/**
 * validate a social security number
 * @example
 * // returns true
 * validateSSN("33110782D493", {gender: "M", lastName: "Doe"});
 * @param {string} ssn the social security number
 * @param {SsnMetaData} meta data needed to validate the social security number
 * @returns {boolean} true if valid, false if invalid
 */

export const validateSSN = (
  ssn: string,
  meta: SsnMetaData,
): React.ReactNode => {
  if (!ssn || !ssn.length) return undefined;
  const defaultErrorMessage = i18n.t(`profile.ssn-validation-error`);

  const birthDateNumbers = extractFromSSN(ssn, SsnFragment.birthDateNumbers);
  const lastNameFirstLetter = extractFromSSN(
    ssn,
    SsnFragment.lastNameFirstLetter,
  );
  const genderNumbers = extractFromSSN(ssn, SsnFragment.genderNumbers);
  const checkNumber = extractFromSSN(ssn, SsnFragment.checkNumber);

  const makeErrorFragmentBold = (
    ssn: string,
    error: string,
    isCheckNumber?: boolean,
  ) => {
    let boldedString;

    if (isCheckNumber) {
      const errorIndex = ssn.lastIndexOf(error);
      boldedString =
        ssn.substring(0, errorIndex) +
        "<b>" +
        error +
        "</b>" +
        ssn.substring(errorIndex + error.length);
    } else {
      boldedString = ssn.replace(error, (match) => `<b>${match}</b>`);
    }
    return (
      <>
        {defaultErrorMessage}{" "}
        <span dangerouslySetInnerHTML={{ __html: boldedString }} />
      </>
    );
  };

  let errorMessage;

  const areBirthdayDateNumbersValid = isValid(
    parse(birthDateNumbers, "ddMMyy", new Date()),
  );

  if (!VALID_INSURANCE_NUMBERS.includes(ssn.slice(0, 2))) {
    errorMessage = makeErrorFragmentBold(ssn, ssn.slice(0, 2));
  } else if (!areBirthdayDateNumbersValid) {
    errorMessage = makeErrorFragmentBold(ssn, birthDateNumbers);
  } else if (!lastNameFirstLetter.match(/^[A-Z]{1}$/)) {
    errorMessage = makeErrorFragmentBold(ssn, lastNameFirstLetter);
  } else if (
    !genderNumbers.match(/^[0-9]{2}$/) ||
    !(
      (meta.gender === "M" && Number(genderNumbers) <= 49) ||
      (meta.gender !== "M" && Number(genderNumbers) > 49)
    )
  ) {
    errorMessage = makeErrorFragmentBold(ssn, genderNumbers);
  } else if (Number(checkNumber) !== calcCheckNumber(ssn)) {
    errorMessage = makeErrorFragmentBold(ssn, checkNumber, true);
  } else {
    return !errorMessage ? undefined : defaultErrorMessage;
  }

  return errorMessage;
};
