import { createContext } from "react";

export type CardContextType = {
  variant: string;
  isLoading: boolean;
};

export const CardContext = createContext<CardContextType>({
  variant: "default",
  isLoading: false,
});
