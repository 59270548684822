import React from "react";
import styled from "styled-components";
import { height, margin, padding, variant } from "styled-system";

import { Flex, Box, type BoxProps } from "Shared/flexbox";
import useViewport from "Shared/Viewport/use";
import viewportValues from "Shared/Viewport/values";
import theme from "Shared/theme";
import Grid from "Shared/Grid";

type StyledFlexProps = {
  $backgroundColor?: string;
  $shadow: boolean;
};

type HeaderProps = BoxProps & {
  shadow?: boolean;
  icon?: JSX.Element;
  title?: React.ReactNode;
  menu?: JSX.Element | JSX.Element[];
  variant?: string;
  isIconResponsive?: boolean;
  backgroundColor?: string;
  onClick?: () => void;
};

const StyledFlex = styled(Flex)<StyledFlexProps>`
  ${variant({
    key: "headers",
  })}
  z-index: 1;
  ${({ $shadow }) => ($shadow ? `box-shadow: ${theme.shadows.medium};` : "")}
  ${({ onClick }) => (typeof onClick === "function" ? "cursor: pointer;" : "")}
  ${({ $backgroundColor }) =>
    $backgroundColor ? `background-color: ${$backgroundColor};` : ""}
  ${height}
  ${padding}
  ${margin}
`;

const Header: React.FC<HeaderProps> = ({
  icon = "",
  title = "",
  menu = null,
  isIconResponsive = false,
  alignItems = "center",
  shadow = false,
  variant = "main",
  backgroundColor,
  ...restProps
}) => {
  const viewport = useViewport();
  const isIconVisible = isIconResponsive
    ? viewport !== viewportValues.MOBILE
    : true;

  return (
    <StyledFlex
      width={1}
      p={3}
      alignItems={alignItems}
      $shadow={shadow}
      $backgroundColor={backgroundColor}
      variant={variant}
      {...restProps}
    >
      {icon && isIconVisible && <Box mr={2}>{icon}</Box>}
      {title}
      <Flex ml="auto">
        <Grid gridGap={2} gridAutoFlow="column">
          {menu}
        </Grid>
      </Flex>
    </StyledFlex>
  );
};

export default Header;
