import { darken, lighten } from "polished";

const blue = "#0075b2";
const primary = blue;
const lightblue = "#E6F5FC";
const darkblue = "#015293";
const infoblue = "#006E97";

const commonblack = "#00111A";
const successlight = "#D4F7D2";
const darkError = "#A1352A";
const lightError = "#FCDAD7";

const lightgrey = "#E1E3E5";
const grey = "#d3d3d3";
const darkgrey = "#555555";
const anchorgrey = "#626974";
const subTablesGrey = "#9e9e9e";
const navigationGrey = "#f4f4f4";
const darkengrey = "#59595a";
const darkergrey = "#949596";
const grey500 = "#949596";
const grey50 = "#f8f9fa";
const grey200 = "#e8e9ea";
const grey600 = "#6C6D6E";
const grey300 = "#D8D9DA";

const jacksonPurple = "#1E4A88";
const lightestGreen = "#C7F0C8";
const lightgreen = "#83be58";
const green = "#07bc0c";
const darkGreen = "#048308";
const timetrackinggreen = "#f5fbec";
const timetrackinggreen2 = "#eaf7d5";
const darkred = "#a94442";
const red = "#f44336";
const dangerRed = "#e74c3c";
const warningMain = "#FF8800";

const lightRed = "#fbc9c5";
const yellow = "#FFC300";
const lightYellow = "#FAEBD7";
const lightWarningYellow = "#FFEAD1";
const darkWarningYellow = "#F36C01";
const lightPrimary = "#E6F5FC"; // primary colour with 10% opacity
const orange = "#E77E09";
const lightOrange = "#FF88002E";
const breakpoints = ["40em", "85em", "92em"];
const white = "#FFFFFF";
const lightestGrey = "#f2f5f7";
const black = "#1A222F";
const shortAbsenceColor = "#FFF4DC";
const light = "#F1F2F3";
const codeColor = "#e4e5e7";

const fontSizes = {
  small: "13px",
  medium: "15px",
  regular: "15px",
  large: "18px",
  xlarge: "24px",
  hero: "32px",
  iconMenu: "24px",
};

const shadows = {
  small: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
  medium: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  mediumDark: "0px 2px 4px rgba(0, 0, 0, 0.4)",
  mediumInverse: "1px -2px 4px rgba(0, 0, 0, 0.2)",
  large: "5px 5px 10px rgba(0, 0, 0, 0.2)",
};

const space = [0, 5, 10, 20, 40];

const theme = {
  name: "default",
  breakpoints: breakpoints,
  space,
  fontWeights: [400, 700],
  fontSizes,
  shadows,
  colors: {
    primary,
    lightblue,
    darkblue,
    infoblue,
    light,
    white,
    darkgrey,
    darkergrey,
    grey,
    grey500,
    grey50,
    grey200,
    grey600,
    grey300,
    darkengrey,
    jacksonPurple,
    lightgrey,
    red,
    dangerRed,
    lightestGreen,
    lightRed,
    timetrackinggreen,
    timetrackinggreen2,
    text: darkgrey,
    lightgreen,
    warningMain,
    green,
    darkGreen,
    darkred,
    yellow,
    lightPrimary,
    lightYellow,
    lightWarningYellow,
    darkWarningYellow,
    orange,
    lightOrange,
    blue,
    black,
    anchorgrey,
    subTablesGrey,
    lightestGrey,
    shortAbsenceColor,
    navigationGrey,
    codeColor,
    commonblack,
    successlight,
    darkError,
    lightError,
  },
  buttons: {
    primary: {
      backgroundColor: primary,
      color: white,
      // textTransform: "capitalize",
      border: "none",
      "&:disabled": {
        backgroundColor: light,
        color: grey,
        cursor: "not-allowed",
      },
      [`@media (min-width: ${breakpoints[1]})`]: {
        "&:hover": {
          backgroundColor: darken(0.2, primary),
        },
      },
      "&:focus": {
        backgroundColor: darken(0.2, primary),
      },
    },
    light: {
      backgroundColor: "transparent",
      color: primary,
      // border: `1px solid ${primary}`,
      border: "none",
      "&:disabled": {
        // border: `1px solid ${darkgrey}`,
        // backgroundColor: light,
        cursor: "not-allowed",
        color: grey,
      },
      [`@media (min-width: ${breakpoints[1]})`]: {
        "&:hover": {
          backgroundColor: light,
        },
      },
      "&:focus": {
        backgroundColor: light,
      },
    },
    medium: {
      backgroundColor: lightblue,
      color: darkblue,
      // border: `1px solid ${primary}`,
      border: "none",
      "&:disabled": {
        // border: `1px solid ${darkgrey}`,
        backgroundColor: lightblue,
        cursor: "not-allowed",
        color: grey,
      },
      [`@media (min-width: ${breakpoints[1]})`]: {
        "&:hover": {
          backgroundColor: darken(0.2, lightblue),
        },
      },
      "&:focus": {
        backgroundColor: darken(0.2, lightblue),
      },
    },
    grey: {
      backgroundColor: grey,
      color: black,
      // border: `1px solid ${primary}`,
      border: "none",
      "&:disabled": {
        // border: `1px solid ${darkgrey}`,
        backgroundColor: lightgrey,
        cursor: "not-allowed",
        color: darkgrey,
      },
      [`@media (min-width: ${breakpoints[1]})`]: {
        "&:hover": {
          backgroundColor: darken(0.2, grey),
        },
      },
      "&:focus": {
        backgroundColor: darken(0.2, grey),
      },
    },
    delete: {
      backgroundColor: dangerRed,
      color: white,
      // textTransform: "capitalize",
      border: "none",
      "&:disabled": {
        backgroundColor: lighten(0.2, red),
        color: grey,
        cursor: "not-allowed",
      },
      [`@media (min-width: ${breakpoints[1]})`]: {
        "&:hover": {
          backgroundColor: darken(0.2, red),
        },
      },
      "&:focus": {
        backgroundColor: darken(0.2, red),
      },
    },
    tab: {
      backgroundColor: primary,
      fontSize: "9px",
      color: white,
      borderRadius: "0 0 3px 3px",
      textTransform: "uppercase",
      boxShadow: "inset 0px 5px 10px -5px rgba(0,0,0,0.1)",
    },
    link: {
      border: "none",
      backgroundColor: white,
      color: darkgrey,
      fontSize: "16px !important",
      "&:hover": {
        color: blue,
      },
    },
  },
  fonts: {
    sans: "OpenSans, sans-serif",
    mono: "OpenSans, monospace",
  },
  borders: {
    none: "none",
    lightgrey: "1px solid " + lightgrey,
    lightgreyBold: "2px solid " + lightgrey,
    lightgreyBoldDashed: "2px dashed " + lightgrey,
    grey: "1px solid " + grey,
    greyBold: "2px solid " + grey,
    darkgreyBold: "2px solid " + darken(0.2, grey),
    primary: "1px solid " + primary,
    primaryBold: "2px solid " + primary,
    warning: "1px solid " + yellow,
    danger: "1px solid " + darkred,
    dangerBold: "2px solid " + darkred,
    dangerBoldDashed: "2px dashed " + darkred,
    white: "1px solid white",
    whiteThickBold: "4px solid white",
    blueBold: "2px solid" + blue,
    blueThickBold: "3px solid" + blue,
    lightBlue: "1px solid" + lightblue,
    lightBlueBold: "2px solid" + lightblue,
    black: "1px solid" + black,
  },
  radii: [0, 3, 25],
  avatarSizes: {
    tiny: {
      height: "28px",
      width: "28px",
    },
    small: {
      height: "48px",
      width: "48px",
    },
    medium: {
      height: "120px",
      width: "120px",
    },
    large: {
      height: "160px",
      width: "160px",
    },
  },
  cards: {
    dark: {
      border: "1px solid " + grey,
      borderRadius: "3px",
    },
    dialogue: {
      borderRadius: "3px",
      boxShadow: "0px 0px 5px 2px lightgrey",
    },
    flat: {
      border: "1px solid " + lightgrey,
      borderRadius: "3px",
    },
    default: {
      border: "none",
      borderRadius: "24px",
      backgroundColor: "white",
      height: "fit-content",
      position: "relative",
    },
    primary: {
      border: "1px solid " + lightgrey,
      borderTop: "2px solid " + primary,
      minHeight: "120px",
    },
  },
  cardHeaders: {
    flat: {
      fontWeight: 700,
      color: primary,
      padding: "12px 24px",
    },
    default: {
      padding: "32px 24px",
      borderTopLeftRadius: "24px",
      borderTopRightRadius: "24px",
      fontSize: fontSizes.large,
      fontWeight: 700,
    },
    primary: {
      fontWeight: 700,
      fontSize: "medium",
      alignSelf: "center",
    },
    modal: {
      fontWeight: 700,
      paddingTop: "5px",
      paddingRight: "10px",
      paddingLeft: "10px",
      paddingBottom: 0,
      textTransform: "uppercase",
    },
    dialogue: {
      fontWeight: 700,
      fontSize: "large",
      color: primary,
      textTransform: "uppercase",
      marginRight: "20px",
      marginLeft: "20px",
      marginBottom: "20px",
      paddingTop: "20px",
      paddingBottom: "5px",
      paddingRight: 0,
      paddingLeft: 0,
      borderBottom: "2px solid #F2F2F2",
      justifyContent: "flex-start",
    },
    plain: {
      padding: 0,
      margin: 0,
    },
  },
  cardBodies: {
    flat: {
      padding: "0px 24px 24px 24px",
    },
    primary: {},
    modal: {
      paddingBottom: "5px",
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    dialogue: {
      paddingBottom: "20px",
      paddingRight: "20px",
      paddingLeft: "20px",
    },
    default: { padding: "0px 24px 24px 24px" },
    plain: {
      padding: 0,
      margin: 0,
      "& button": {
        borderRadius: 0,
      },
    },
  },
  cardFooters: {
    default: {
      padding: "10px 0 10px",
      borderBottomLeftRadius: "24px",
      borderBottomRightRadius: "24px",
      borderTop: "1px solid #E1E3E5",
      marginTop: "auto",
    },
  },
  headers: {
    main: {
      // fontWeight: 700,
      fontSize: "medium",
      backgroundColor: primary,
      color: white,
      textTransform: "uppercase",
    },
    mainInverted: {
      fontWeight: 700,
      color: primary,
      backgroundColor: white,
      textTransform: "uppercase",
    },
    sub: {
      fontWeight: 700,
      color: darkgrey,
      padding: 0,
      height: "40px",
      marginBottom: "10px",
      marginTop: "10px",
      borderBottom: `1px solid ${grey}`,
      fontSize: fontSizes.large,
    },
    wizard: {
      fontWeight: 400,
      color: darkgrey,
      fontSize: fontSizes.large,
      textTransform: "uppercase",
    },
    modal: {
      color: primary,
      fontWeight: 700,
      padding: 0,
      height: "60px",
      // borderBottom: `1px solid ${grey}`
    },
    primary: {
      color: darkgrey,
      fontWeight: 700,
      borderBottom: `2px solid ${grey}`,
      fontSize: fontSizes.large,
      textTransform: "uppercase",
      alignItems: "flex-end",
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      paddingRight: 0,
      paddingLeft: 0,
    },
    secondary: {
      color: darkgrey,
      fontWeight: 700,
      borderBottom: `2px solid ${grey}`,
      fontSize: fontSizes.medium,
      // textTransform: "capitalize",
      alignItems: "flex-end",
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
} satisfies Record<string, any>;

export type Theme = typeof theme;

export default theme;
