import styled from "styled-components";
import type { IconProps } from "Shared/Icon";
import Icon from "Shared/Icon";
import type { Optional } from "types";

type LoadingIconProps = Optional<IconProps, "spin" | "icon">;

const LoadingIcon = styled(Icon).attrs({
  spin: true,
  icon: "settings",
})<LoadingIconProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default LoadingIcon;
