import type {
  EndSessionAction,
  SessionAction,
  SessionState,
  SetAzureLogoutRedirectAction,
  SetConfirmBeforeNavigateAction,
  SetLastPathAction,
  SetLastQueryStringAction,
  StartSessionAction,
  UpdateUserAction,
} from "./types";

export const START = "session/START";
export const END = "session/END";
export const UPDATE_USER = "session/UPDATE_USER";
export const SET_CONFIRM_BEFORE_NAVIGATE =
  "session/SET_CONFIRM_BEFORE_NAVIGATE";
export const SET_HAS_UNREAD_NOTIFICATIONS =
  "session/SET_HAS_UNREAD_NOTIFICATIONS";
export const SET_LAST_PATH = "session/SET_LAST_PATH";
export const SET_LAST_QUERY_STRING = "session/SET_LAST_QUERY_STRING";
export const SET_AZURE_LOGOUT_REDIRECT = "session/SET_AZURE_LOGOUT_REDIRECT";

//Action Creators

export function startSession({
  user,
  subscription,
  companies,
  teams,
  companiesFrontendConfiguration,
  sectionAccess,
  mapsApiKey,
  logo,
  signInWith,
  whistleblowingActivated,
  azureLogoutRedirectMicrosoft,
}: StartSessionAction["payload"]): StartSessionAction {
  return {
    type: START,
    payload: {
      user,
      subscription,
      companies,
      teams,
      companiesFrontendConfiguration,
      sectionAccess,
      mapsApiKey,
      logo,
      signInWith,
      whistleblowingActivated,
      azureLogoutRedirectMicrosoft,
    },
  };
}

export function endSession(): EndSessionAction {
  return { type: END };
}

export function setConfirmBeforeNavigate(
  payload: SessionState["confirmBeforeNavigate"],
): SetConfirmBeforeNavigateAction {
  return { type: SET_CONFIRM_BEFORE_NAVIGATE, payload };
}

export function updateUser(
  payload: UpdateUserAction["payload"],
): UpdateUserAction {
  return { type: UPDATE_USER, payload };
}

export function setLastPath(
  payload: SessionState["lastPath"] = null,
): SetLastPathAction {
  return { type: SET_LAST_PATH, payload };
}

export function setLastQueryString(
  payload: SessionState["lastQueryString"] = "",
): SetLastQueryStringAction {
  return { type: SET_LAST_QUERY_STRING, payload };
}

export function updateAzureLogoutRedirect(
  payload: SessionState["azureLogoutRedirectMicrosoft"] = false,
): SetAzureLogoutRedirectAction {
  return { type: SET_AZURE_LOGOUT_REDIRECT, payload };
}

// Reducer
export const initialState: SessionState = {
  isActive: false,
  // @ts-ignore
  user: null,
  subscription: null,
  companies: [],
  branchOffices: [],
  teams: [],
  companiesFrontendConfiguration: {
    configuration: [],
    defaults: [],
  },
  // @ts-ignore
  sectionAccess: {},
  mapsApiKey: null,
  hasUnreadNotifications: false,
  lastPath: null,
  lastQueryString: "",
  logo: null,
  signInWith: null,
  whistleblowingActivated: false,
  azureLogoutRedirectMicrosoft: false,
};

const sessionReducer = (
  state: SessionState,
  action: SessionAction,
): SessionState => {
  switch (action.type) {
    case START:
      return {
        ...state,
        isActive: true,
        user: action.payload.user,
        subscription: action.payload.subscription,
        companies: action.payload.companies,
        teams: action.payload.teams,
        companiesFrontendConfiguration:
          action.payload.companiesFrontendConfiguration,
        sectionAccess: action.payload.sectionAccess,
        mapsApiKey: action.payload.mapsApiKey,
        logo: action.payload.logo,
        signInWith: action.payload.signInWith,
        whistleblowingActivated: action.payload.whistleblowingActivated,
        azureLogoutRedirectMicrosoft:
          action.payload.azureLogoutRedirectMicrosoft,
      };
    case END:
      return {
        ...state,
        isActive: false,
        // @ts-ignore
        user: null,
        subscription: null,
        companies: [],
        branchOffices: [],
        teams: [],
        companiesFrontendConfiguration: {
          configuration: [],
          defaults: [],
        },
        // @ts-ignore
        sectionAccess: {},
        mapsApiKey: null,
      };
    case SET_CONFIRM_BEFORE_NAVIGATE:
      return {
        ...state,
        confirmBeforeNavigate: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case SET_HAS_UNREAD_NOTIFICATIONS:
      return { ...state, hasUnreadNotifications: action.payload };
    case SET_LAST_PATH:
      return {
        ...state,
        lastPath: action.payload,
      };
    case SET_LAST_QUERY_STRING:
      return {
        ...state,
        lastQueryString: action.payload,
      };
    case SET_AZURE_LOGOUT_REDIRECT:
      return {
        ...state,
        azureLogoutRedirectMicrosoft: action.payload,
      };
    default:
      return state;
  }
};

export default sessionReducer;
