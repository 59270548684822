import axios from "axios";
import initXSRFToken from "./initXSRFToken";

const publicAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT_URL}`,
  withCredentials: true,
  headers: {
    "x-requested-with": "XMLHttpRequest",
  },
});

initXSRFToken();

export default publicAxios;
