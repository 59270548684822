import React from "react";
import styled, { css } from "styled-components";
import type { TypographyProps, PositionProps } from "styled-system";
import { typography, position } from "styled-system";

import { Flex, type BoxProps } from "Shared/flexbox";
import shouldNotForwardProps from "Shared/helpers/shouldNotForwardProps";

import "./index.css";
import type { iconsList } from "./constants";

type StyledFlexProps = TypographyProps & PositionProps & BoxProps;

export type IconType = (typeof iconsList)[number];

export type IconProps = StyledFlexProps & HRlabIconProps & { icon: IconType };

type HRlabIconProps = React.HTMLAttributes<HTMLDivElement> & {
  spin?: boolean;
};

const StyledBox = styled(Flex)<StyledFlexProps>(typography, position);

const HRlabIcon = styled.div.withConfig(
  shouldNotForwardProps(["spin"]),
)<HRlabIconProps>`
  display: inline-block;
  ${(props) =>
    props.spin
      ? css`
          animation: spin 2000ms linear infinite;
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `
      : ``};
`;

const Icon: React.FC<IconProps> = ({ icon, spin, ...restProps }) => (
  <StyledBox {...restProps}>
    {<HRlabIcon className={`hrlab hrlab-${icon}`} spin={spin} />}
  </StyledBox>
);

export default Icon;
