import type { Company } from "Main/Company/types";
import type { Team } from "Main/Teams/types";
import api from "Shared/Axios/public";
import type {
  CompanyFrontendConfiguration,
  SectionAccess,
  SessionUser,
  SignInWith,
} from "Shared/Session/types";

export type BaseData = {
  user: SessionUser;
  subscription: {
    trial: boolean;
    is_subscription_valid: boolean;
  };
  companies: Company[];
  teams: Team[];
  companiesFrontendConfiguration: CompanyFrontendConfiguration;
  sectionAccess: SectionAccess;
  form_authenticity_token: string;
  mapsApiKey: string;
  logo: string;
  signInWith: SignInWith;
  whistleblowingActivated: boolean;
  azureLogoutRedirectMicrosoft: boolean;
};

export const getBaseData = async (): Promise<BaseData> => {
  const responseInit = await api.get(`/initialize_application`, {
    baseURL: process.env.REACT_APP_API_ROOT_URL,
  });

  if (responseInit.request.responseURL.includes("/auth/")) {
    throw new Error("401 not welcome");
  }

  const form_authenticity_token = responseInit?.data?.form_authenticity_token;
  const user = responseInit?.data?.userData;
  const subscription = responseInit?.data?.subscriptionData;
  const companies = responseInit?.data?.companies;
  const teams = responseInit?.data?.teams;
  const companiesFrontendConfiguration =
    responseInit?.data?.companiesFrontendConfiguration;
  const sectionAccess = responseInit?.data?.configurations?.section_access;
  const mapsApiKey = responseInit?.data?.configurations?.maps_key;
  const logo = responseInit?.data?.customLogo;
  const signInWith = responseInit?.data?.sign_in_with;
  const whistleblowingActivated = responseInit?.data?.whistleblowing_activated;
  const azureLogoutRedirectMicrosoft =
    responseInit?.data?.azure_logout_redirect_microsoft;

  return {
    user,
    subscription,
    companies,
    teams,
    companiesFrontendConfiguration,
    sectionAccess,
    form_authenticity_token,
    mapsApiKey,
    logo,
    signInWith,
    whistleblowingActivated,
    azureLogoutRedirectMicrosoft,
  };
};
