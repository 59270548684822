import type { WebTarget } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

type Return = (propName: string, target: WebTarget) => boolean;

const shouldForwrardProps = (propName: string, target: WebTarget): boolean => {
  if (typeof target !== "string") {
    if (["isPlain", "hasError"].includes(propName)) {
      return false;
    }

    return true;
  }

  if (["onHover", "isPlain"].includes(propName)) {
    return false;
  }

  return isPropValid(propName);
};

const useShouldForwardProp = (): Return => shouldForwrardProps;

export default useShouldForwardProp;
