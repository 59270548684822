import type { ModalState } from "./types";

type ModalAction =
  | { type: "open_modal"; payload: ModalState }
  | { type: "close_modal" };

export const openModal = (
  title: ModalState["title"],
  body: ModalState["body"],
  isDialogue: ModalState["isDialogue"],
  dialogue: ModalState["dialogue"] = {
    cancelButton: null,
    confirmButton: null,
  },
  width: ModalState["width"],
  isCalendarHeader: ModalState["isCalendarHeader"],
  closeCallback: ModalState["closeCallback"],
): ModalAction => ({
  type: "open_modal",
  payload: {
    title,
    body,
    isDialogue,
    dialogue,
    width,
    isCalendarHeader,
    closeCallback,
  },
});

export const closeModal = (): ModalAction => ({
  type: "close_modal",
});

export const initialState: ModalState = {
  isOpen: false,
  title: null,
  body: null,
  isDialogue: false,
  dialogue: {
    cancelButton: null,
    confirmButton: null,
  },
  closeCallback: null,
  isCalendarHeader: false,
};

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case "open_modal":
      return {
        ...state,
        title: action.payload?.title,
        body: action.payload?.body,
        isOpen: true,
        dialogue: action.payload?.dialogue,
        width: action.payload?.width,
        isDialogue: action.payload?.isDialogue ?? initialState.isDialogue,
        isCalendarHeader:
          action.payload?.isCalendarHeader ?? initialState.isCalendarHeader,
        closeCallback: action.payload?.closeCallback,
      };
    case "close_modal":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default modalReducer;
