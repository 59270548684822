type FunctionVariable<T = any> = (callbackParam?: any) => T;

const callOrReturn = <T = any>(
  variable: T | FunctionVariable<T>,
  callbackParam?: any,
): T => {
  return typeof variable === "function"
    ? (variable as FunctionVariable)(callbackParam)
    : variable;
};

export default callOrReturn;
