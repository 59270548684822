import { createContext } from "react";
import type {
  SessionState,
  StartSessionAction,
  UpdateUserAction,
} from "./types";

export type SessionContextType = SessionState & {
  start: (payload: StartSessionAction["payload"], token: string) => void;
  end: (message?: string) => void;
  updateUser: (data: UpdateUserAction["payload"]) => void;
  setLastPath: (lastPath: string | null) => void;
  setLastQueryString: (lastQueryString: string) => void;
  updateAzureLogoutRedirect: (shouldRedirect: boolean) => void;
  setConfirmBeforeNavigate: (confirmBeforeNavigation: boolean) => void;
};

// @ts-ignore
export default createContext<SessionContextType>({});
