import React, { useCallback, useReducer } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import api from "Shared/Axios/api";
import callOrReturn from "Shared/helpers/callOrReturn";
import type { SessionContextType } from "./Context";
import SessionContext from "./Context";
import reducer, {
  initialState,
  startSession,
  endSession,
  updateUser as _updateUser,
  setConfirmBeforeNavigate as _setConfirmBeforeNavigate,
  setLastPath as _setLastPath,
  setLastQueryString as _setLastQueryString,
  updateAzureLogoutRedirect as _updateAzureLogoutRedirect,
} from "./duck";
import type { SessionAction, SessionState } from "./types";
import { type StartSessionAction, type UpdateUserAction } from "./types";

type SessionProviderProps = {
  children: React.ReactNode | ((ctx: SessionContextType) => React.ReactNode);
};

const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<
    React.Reducer<SessionState, SessionAction>
  >(reducer, initialState);

  const start = useCallback(
    (
      {
        user,
        subscription,
        companies,
        teams,
        companiesFrontendConfiguration,
        sectionAccess,
        mapsApiKey,
        logo,
        signInWith,
        whistleblowingActivated,
        azureLogoutRedirectMicrosoft,
      }: StartSessionAction["payload"],
      token: string,
    ) => {
      axios.defaults.headers.common["X-CSRF-Token"] = token;
      api.defaults.headers.common["X-CSRF-Token"] = token;
      dispatch(
        startSession({
          user,
          subscription,
          companies,
          teams,
          companiesFrontendConfiguration,
          sectionAccess,
          mapsApiKey,
          logo,
          signInWith,
          whistleblowingActivated,
          azureLogoutRedirectMicrosoft,
        }),
      );
    },
    [],
  );

  const end = useCallback((message?: string): void => {
    const id = "logout";

    if (typeof message === "string") {
      toast.success(message, { toastId: id, autoClose: 2000 });
    }

    dispatch(endSession());
  }, []);

  const setConfirmBeforeNavigate = useCallback(
    (confirmBeforeNavigation: boolean): void => {
      dispatch(_setConfirmBeforeNavigate(confirmBeforeNavigation));
    },
    [],
  );

  const updateUser = useCallback((data: UpdateUserAction["payload"]): void => {
    dispatch(_updateUser(data));
  }, []);

  const setLastPath = useCallback((lastPath: string | null): void => {
    dispatch(_setLastPath(lastPath));
  }, []);

  const setLastQueryString = useCallback((lastQueryString: string): void => {
    dispatch(_setLastQueryString(lastQueryString));
  }, []);

  const updateAzureLogoutRedirect = useCallback(
    (shouldRedirect: boolean = false): void => {
      dispatch(_updateAzureLogoutRedirect(shouldRedirect));
    },
    [],
  );

  const ctx: SessionContextType = {
    ...state,
    start,
    end,
    updateUser,
    setLastPath,
    setLastQueryString,
    updateAzureLogoutRedirect,
    setConfirmBeforeNavigate,
  };

  return (
    <SessionContext.Provider value={ctx}>
      {callOrReturn(children, ctx)}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
