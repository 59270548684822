import React from "react";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import type { LayoutProps, FlexboxProps } from "styled-system";

import type { IconType } from "Shared/Icon";
import Icon, { type IconProps } from "Shared/Icon";
import { Flex, Box } from "Shared/flexbox";

import Wrapper from "./Wrapper";
import LoadingIcon from "./LoadingIcon";
import type { StyledButtonProps } from "./StyledButton";
import StyledButton from "./StyledButton";

export type ButtonProps = StyledButtonProps & {
  large?: boolean;
  icon?: IconType;
  tooltip?: React.ReactNode;
  iconProps?: Omit<IconProps, "icon">;
  tooltipPlacement?: "bottom" | "top" | "left" | "right" | undefined;
  ref?: React.RefObject<HTMLButtonElement>;
  round?: boolean;
  tooltipMouseLeaveDelay?: number;
  noHighlight?: boolean;
  loading?: boolean;
  label?: React.ReactNode;
};

type ButtonContentProps = FlexboxProps &
  LayoutProps &
  React.HTMLAttributes<HTMLDivElement> & {
    hidden?: boolean;
  };

const Content = styled(Flex)<ButtonContentProps>`
  visibility: ${({ hidden = false }) => (hidden ? "hidden" : "visible")};
  align-items: center;
  /* justify-content: center; */
`;

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    loading = false,
    disabled = false,
    variant = "primary",
    px = "10px",
    fontSize = "medium",
    height = "40px",
    icon,
    iconProps,
    label,
    round = false,
    tooltip,
    tooltipPlacement = "bottom",
    tooltipMouseLeaveDelay = 0,
    color = "inherit",
    large,
    justifyContent = "center",
    type = "button",
    onClick,
    ...restProps
  },
  ref,
) => {
  const { t } = useTranslation();

  const getTooltip = (icon: string) => {
    switch (icon) {
      case "caret-down":
        return t("shared.fade-in");
      case "caret-left":
        return t("shared.previous");
      case "caret-right":
        return t("shared.next");
      case "caret-up":
        return t("shared.fade-out");
      case "close":
      case "times":
        return t("shared.close");
      case "copy":
        return t("shared.copy-item");
      case "list":
        return t("", "List");
      case "pause":
        return t("time-tracking.pause");
      case "stop":
        return t("time-tracking.stop");
      case "play":
        return t("time-tracking.start");
      case "pen":
        return t("shared.edit");
      case "plus":
        return t("shared.add");
      case "save":
        return t("shared.save");
      case "sort-down":
        return t("shared.sort-ascending");
      case "sort-up-2":
        return t("shared.sort-descending");
      case "trash":
        return t("shared.delete");
      default:
        return "Click Me";
    }
  };

  // @ts-ignore
  const debouncedClickHandler = React.useMemo(() => {
    if (!onClick) {
      return;
    }

    return debounce(onClick, 500, { trailing: false, leading: true });
  }, [onClick]);

  return (
    <Wrapper
      condition={!label}
      wrapper={(children: React.ReactElement) => (
        <Tooltip
          overlay={tooltip ? tooltip : getTooltip(icon || "")}
          overlayStyle={{
            zIndex: 2000,
            width: "fit-content",
          }}
          placement={tooltipPlacement}
          mouseLeaveDelay={tooltipMouseLeaveDelay}
        >
          {children}
        </Tooltip>
      )}
    >
      <StyledButton
        disabled={disabled || loading}
        variant={variant}
        px={px}
        borderRadius={round ? "50%" : "8px"}
        height={large ? "100px" : height}
        justifyContent={justifyContent}
        color={color}
        bg="inherit"
        type={type}
        onClick={debouncedClickHandler}
        ref={ref}
        {...restProps}
      >
        {loading && <LoadingIcon />}
        <Content hidden={loading} width={1} justifyContent={justifyContent}>
          {icon && (
            <Icon
              icon={icon}
              fontSize={!large ? "20px" : "44px"}
              {...iconProps}
            />
          )}
          {label && (
            <Box fontSize={fontSize} ml={2} pr={10}>
              {label}
            </Box>
          )}
        </Content>
      </StyledButton>
    </Wrapper>
  );
};

export default styled(React.forwardRef(Button))``;
