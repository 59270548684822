import React from "react";
import { useTranslation } from "react-i18next";

import Card from "Shared/Card";
import Flex, { Box } from "Shared/flexbox";
import Link from "Shared/Link";
import Button from "Shared/Button";

type ModalBodyProps = { onContinueClick: () => void };

const ModalBody: React.FC<ModalBodyProps> = ({ onContinueClick }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Box mt="10px" mb="40px">
          {t("download-mobile-app-modal.description")}
        </Box>
        <Flex flexDirection="column" style={{ gap: "20px" }}>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" style={{ gap: "10px" }}>
              <img
                src={process.env.PUBLIC_URL + "/images/icon-apple-store.png"}
                alt="apple store"
              />
              <span>{t("download-mobile-app-modal.app-for")} iOS</span>
            </Flex>
            <Link
              target="_blank"
              to="https://apps.apple.com/de/app/hrlab/id1627456521"
            >
              <Button label={t("download-mobile-app-modal.open")} />
            </Link>
          </Flex>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" style={{ gap: "10px" }}>
              <img
                src={process.env.PUBLIC_URL + "/images/icon-play-store.png"}
                alt="play store"
              />
              <span>{t("download-mobile-app-modal.app-for")} Android</span>
            </Flex>
            <Link
              target="_blank"
              to="https://play.google.com/store/apps/details?id=de.hrlab.mobileapp"
            >
              <Button label={t("download-mobile-app-modal.open")} />
            </Link>
          </Flex>
        </Flex>
      </Card.Body>

      <Card.Footer justifyContent="right">
        <Button
          label={t("download-mobile-app-modal.continue-in-browser")}
          variant="light"
          onClick={onContinueClick}
        />
      </Card.Footer>
    </Card>
  );
};

export default ModalBody;
