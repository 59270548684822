import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import theme from "Shared/theme";
import callOrReturn from "Shared/helpers/callOrReturn";
import type { ViewportContextType } from "./Context";
import ViewportContext from "./Context";
import viewportValues from "./values";

type ViewportProviderProps = {
  children: React.ReactNode | ((ctx: ViewportContextType) => React.ReactNode);
};

const ViewportProvider: React.FC<ViewportProviderProps> = ({ children }) => {
  const [viewport, setViewport] = useState<ViewportContextType>(
    viewportValues.MOBILE,
  );

  const [mobile, tablet] = theme.breakpoints;

  const isMobile = useMediaQuery({
    query: `(max-width: ${mobile})`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${tablet})`,
  });

  useEffect(() => {
    const viewportBool = [isMobile, isTablet, true];
    const viewport = [
      viewportValues.MOBILE,
      viewportValues.TABLET,
      viewportValues.DESKTOP,
    ].find((_, i) => viewportBool[i]);
    setViewport(viewport || viewportValues.MOBILE);
  }, [isMobile, isTablet, setViewport]);

  const ctx = viewport;

  return (
    <ViewportContext.Provider value={ctx}>
      {callOrReturn(children, ctx)}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
