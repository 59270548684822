import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "styled-components";

import theme from "Shared/theme";
import Header from "Shared/Header";
import Icon from "Shared/Icon";
import Button from "Shared/Button";
import type { IconType } from "Shared/Icon/Icon";
import type { Width } from "./types";

export type ModalProps = {
  isOpen?: boolean;
  title?: React.ReactNode;
  body?: React.ReactNode | (() => JSX.Element);
  hideCloseIcon?: boolean;
  headerIcon?: IconType;
  width?: Width;
  scrollType?: "body" | "paper";
  headerBg?: string;
  onClose?: () => void;
};

const Modal: React.FC<ModalProps> = ({
  isOpen = false,
  title = "[MODAL TITLE]",
  body = "[MODAL BODY]",
  headerIcon,
  hideCloseIcon = false,
  scrollType = "body",
  width = "md",
  headerBg = "inherit",
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        disableEnforceFocus
        scroll={scrollType}
        fullWidth
        maxWidth={width}
        open
        sx={{ borderRadius: "24px" }}
        onClose={onClose}
      >
        <DialogTitle sx={{ p: 0 }}>
          <Header
            backgroundColor={headerBg || "inherit"}
            icon={headerIcon && <Icon icon={headerIcon} />}
            variant="modal"
            m={0}
            p={3}
            title={title}
            menu={
              !hideCloseIcon ? (
                <Button
                  data-testid="close-button"
                  variant="secondary"
                  onClick={onClose}
                  icon="close"
                  tabIndex={-1}
                  width="auto"
                  height="auto"
                  mr="5px"
                  border="none"
                />
              ) : undefined
            }
          />
        </DialogTitle>
        <DialogContent sx={{ p: 0 }} dividers={scrollType === "paper"}>
          {typeof body === "function" ? body() : body}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default Modal;
