import React, { useContext } from "react";
import styled from "styled-components";
import type { MaxHeightProps } from "styled-system";
import { maxHeight, variant } from "styled-system";

import type { BoxProps } from "Shared/flexbox";
import { Flex } from "Shared/flexbox";
import Overlay from "Shared/Overlay";
import { CardContext } from "./CardContext";

export type CardBodyProps = BoxProps &
  MaxHeightProps & {
    style?: React.CSSProperties;
  };

const CardBodyPrimitive = styled(Flex)`
  ${variant({
    key: "cardBodies",
  })}
  ${maxHeight}
`;

const CardBody: React.FC<CardBodyProps> = ({
  p = 2,
  pt = 0,
  flex = 1,
  flexDirection = "column",
  ...restProps
}) => {
  const { variant, isLoading } = useContext(CardContext);

  const newProps = {
    p,
    pt,
    flex,
    flexDirection,
    ...restProps,
    variant,
  };

  return (
    <>
      {isLoading && <Overlay />}
      <CardBodyPrimitive {...newProps} />
    </>
  );
};

export default CardBody;
