import React, { useContext } from "react";
import styled from "styled-components";
import { variant } from "styled-system";

import type { BoxProps } from "Shared/flexbox";
import { Flex } from "Shared/flexbox";
import { CardContext } from "./CardContext";

export type CardHeaderProps = BoxProps & {
  border?: boolean;
};

type CardHeaderPrimitiveProps = BoxProps & { $border: boolean };

const CardHeaderPrimitive = styled(Flex)<CardHeaderPrimitiveProps>`
  border-bottom: ${(props) => (props.$border ? "1px solid #E1E3E5" : "none")};
  ${variant({
    key: "cardHeaders",
  })}
`;

const CardHeader: React.FC<CardHeaderProps> = ({
  py = 2,
  px = 3,
  width = 1,
  alignItems = "center",
  height = "50px",
  border = false,
  ...restProps
}) => {
  const { variant } = useContext(CardContext);

  const newProps = {
    py,
    px,
    width,
    alignItems,
    height,
    $border: border,
    ...restProps,
    variant,
  };

  return <CardHeaderPrimitive {...newProps} />;
};

export default CardHeader;
