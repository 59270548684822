import { useCallback, useReducer } from "react";

import reducer, { initialState, openModal, closeModal } from "./duck";
import type { ModalState } from "./types";

type ModalType = Omit<ModalState, "closeCallback">;

export type OpenParam = ModalType & { closeCallback?: () => void };

export type ModalProps = ModalType & {
  onClose: () => void;
};

export type ModalActions = {
  openModal: (modalType: OpenParam) => void;
  closeModal: () => void;
};

export const useModal = (): [ModalProps, ModalActions] => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isOpen, title, body, isDialogue, dialogue, width, isCalendarHeader } =
    state;

  const handleOpenModal = useCallback(
    ({
      title,
      body,
      isDialogue,
      dialogue,
      width,
      isCalendarHeader,
      closeCallback,
    }: OpenParam) => {
      dispatch(
        openModal(
          title,
          body,
          isDialogue,
          dialogue,
          width,
          isCalendarHeader,
          closeCallback,
        ),
      );
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
    state.closeCallback?.();
  }, [state]);

  const modalProps = {
    isOpen,
    title,
    body,
    isDialogue,
    dialogue,
    width,
    isCalendarHeader,
    onClose: handleCloseModal,
  };

  const actions = { openModal: handleOpenModal, closeModal: handleCloseModal };

  return [modalProps, actions];
};
