import React from "react";
import { useTranslation } from "react-i18next";

import { logout } from "Auth/Logout/api";
import api, { LOCALE_PARAM } from "Shared/Axios/api";
import useSession from "Shared/Session/use";

const shouldLogoutOn401 = (pathOrURL: string): boolean => {
  return !pathOrURL.includes("api_auth/") && !pathOrURL.includes("auth/");
};

const useAxiosInterceptors = (): void => {
  const { end: endSession } = useSession();
  const { i18n, t } = useTranslation();

  // handle response interceptors
  React.useEffect(() => {
    const errorInterceptor = async (error: any): Promise<any> => {
      if (error?.response?.status === 401 || error?.status === 401) {
        //handle redirect path
        const prefixLength = (process.env.REACT_APP_ROUTE_PREFIX || "").length;
        const currentPath = window.location.pathname.slice(
          1 + (prefixLength ? prefixLength : 0),
        );
        if (
          shouldLogoutOn401(currentPath) ||
          shouldLogoutOn401(error.request.responseURL)
        ) {
          try {
            await logout();
            const addRedirect =
              currentPath?.length &&
              !currentPath.includes("api_auth/") &&
              !currentPath.includes("auth/");
            let redirectUrl = `${process.env.REACT_APP_ROUTE_PREFIX}/auth/login`;
            if (addRedirect) redirectUrl += `?redirect=${currentPath}`;
            window.location.assign(redirectUrl);
            endSession(t("auth.logout-auto"));
          } catch (_) {}
        }
      }
      return Promise.reject(error);
    };
    const axiosResponseInterceptorId = api.interceptors.response.use(
      undefined,
      errorInterceptor,
    );

    return () => {
      api.interceptors.response.eject(axiosResponseInterceptorId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle request interceptors
  React.useEffect(() => {
    const axiosRequestInterceptorId = api.interceptors.request.use((config) => {
      if (config.url) {
        config.url = config.url.replace(LOCALE_PARAM, i18n.language);
      }
      if (config.baseURL) {
        config.baseURL = config.baseURL.replace(LOCALE_PARAM, i18n.language);
      }
      return config;
    });

    return () => {
      api.interceptors.request.eject(axiosRequestInterceptorId);
    };
  }, [i18n.language]);
};

export default useAxiosInterceptors;
