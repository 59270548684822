import type {
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  BorderProps,
  TypographyProps,
  BorderRadiusProps,
  TextAlignProps,
  GridAreaProps,
} from "styled-system";
import {
  color,
  space,
  variant,
  layout,
  border,
  typography,
  borderRadius,
  textAlign,
  gridArea,
} from "styled-system";
import styled from "styled-components";

import theme from "Shared/theme";
import shouldNotForwardProps from "Shared/helpers/shouldNotForwardProps";

// TODO: double check that all these variant really exist
export type ButtonVariant =
  | "primary"
  | "danger"
  | "warning"
  | "cancel"
  | "secondary"
  | "light"
  | "delete"
  | "medium"
  | "grey"
  | "plain";

export type StyledButtonProps = ColorProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  BorderProps &
  BorderRadiusProps &
  TextAlignProps &
  GridAreaProps &
  TypographyProps & {
    variant?: ButtonVariant;
    shadow?: boolean;
    disabledColor?: string;
    noHighlight?: boolean;
  };

const buttonVariant = variant({
  key: "buttons",
});

const StyledButton = styled.button.withConfig(
  shouldNotForwardProps([
    "noHighlight",
    "borderRadius",
    "justifyContent",
    "justifySelf",
    "disabledColor",
    "shadow",
  ]),
)<StyledButtonProps>`
  box-shadow: ${({ shadow = false }) => (shadow ? theme.shadows.medium : "")};
  ${(props) => {
    return props.color && props.color !== "inherit"
      ? `color: ${props.color} !important;`
      : color;
  }}
  ${space}
  ${layout}
  ${border}
  ${borderRadius}
  ${typography}
  ${textAlign}
  ${gridArea}
  ${buttonVariant}
  min-height: 22px;
  line-height: 22px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  position: relative;
  &:hover,
  &:active,
  &:focus {
    background-color: ${({ noHighlight }) =>
      noHighlight && "inherit !important"};
    cursor: ${({ noHighlight }) => noHighlight && "auto !important"};
  }
  &:disabled {
    cursor: not-allowed;
    ${({ disabledColor = undefined }) => {
      return disabledColor ? `background: ${disabledColor} !important;` : "";
    }}
  }
  transition: all 0.3s ease;
`;

export default StyledButton;
