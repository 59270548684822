import React, { useContext } from "react";
import styled from "styled-components";
import { variant } from "styled-system";

import type { BoxProps } from "Shared/flexbox";
import { Flex } from "Shared/flexbox";
import { CardContext } from "./CardContext";

export type CardFooterProps = BoxProps;

const CardFooterPrimitive = styled(Flex)(
  variant({
    key: "cardFooters",
  }),
);

const CardFooter: React.FC<CardFooterProps> = ({
  alignItems = "center",
  height = "50px",
  ...restProps
}) => {
  const { variant } = useContext(CardContext);

  const newProps = {
    alignItems,
    height,
    ...restProps,
    variant,
  };
  return <CardFooterPrimitive {...newProps} />;
};

export default CardFooter;
