import type React from "react";
import styled from "styled-components";
import type {
  GridAreaProps,
  GridProps as SSGridProps,
  LayoutProps,
  BorderProps,
  BorderTopProps,
  BorderBottomProps,
  BorderLeftProps,
  BorderRightProps,
} from "styled-system";
import {
  grid,
  gridArea,
  layout,
  border,
  borderBottom,
  borderTop,
  borderLeft,
  borderRight,
} from "styled-system";

import type { BoxProps } from "Shared/flexbox";
import { Box } from "Shared/flexbox";

import shouldNotForwardProps from "./helpers/shouldNotForwardProps";

export type GridProps = SSGridProps &
  LayoutProps &
  BoxProps &
  BorderProps &
  BorderTopProps &
  BorderBottomProps &
  BorderLeftProps &
  BorderRightProps;

type GridElementWrapperWrapperProps = GridAreaProps &
  BoxProps & { children?: React.ReactNode };

export const Grid = styled(Box).withConfig(
  shouldNotForwardProps([
    "gridAutoRows",
    "gridAutoColumns",
    "gridAutoFlow",
    "gridTemplateColumns",
    "gridTemplateAreas",
    "gridGap",
    "gridArea",
    "gridColumnGap",
  ]),
)<GridProps>(
  { display: "grid" },
  layout,
  grid,
  border,
  borderBottom,
  borderTop,
  borderLeft,
  borderRight,
);

Grid.displayName = "Grid";

export const GridElementWrapper =
  styled(Box)<GridElementWrapperWrapperProps>(gridArea);

export default Grid;
