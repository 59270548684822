import React from "react";
import * as Sentry from "@sentry/react";

const useSentryUserIdentification = (userId?: number) => {
  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.setUser(userId ? { id: userId } : null);
      // if (!userId)
      //   Sentry.getCurrentScope().clear();
    }
  }, [userId])
};

export default useSentryUserIdentification;
