import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { color, layout, space, typography } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import type { NavLinkProps } from "react-router-dom";
import type { ColorProps, SpaceProps, Theme } from "styled-system";

import type { Optional } from "types";
import shouldNotForwardProps from "Shared/helpers/shouldNotForwardProps";

export type LinkProps = Optional<NavLinkProps, "to" | "target"> &
  SpaceProps &
  ColorProps & {
    plain?: boolean;
    disabled?: boolean;
  };

type StyledLinkProps = Omit<Optional<LinkProps, "to" | "href">, "plain"> & {
  isPlain?: boolean;
};

const plainStyle = `
  color: inherit;
  text-decoration: inherit;
  &:-webkit-any-link, &:hover, &:active, &:visited, &.active {
    color: inherit;
    cursor: pointer;
  }
`;

const defaultStyle = (theme: Theme) => ` 
  display: block;
  text-decoration: none;
  
  color: ${themeGet("colors.text", "darkgrey")({ theme })};
  &:hover {
    color: ${themeGet("colors.primary", "blue")({ theme })};
    cursor: pointer;
  }
  &.active {
    color: ${themeGet("colors.primary", "blue")({ theme })};
    font-weight: bold;
  }`;

export const RouterLink = styled(NavLink).withConfig(
  shouldNotForwardProps(["isPlain"]),
)<StyledLinkProps>`
  ${color}
  ${space}
  ${layout}
  ${typography}
  ${(props) => (props.isPlain ? plainStyle : defaultStyle(props.theme))}
`;

const NativeLink = styled.a.withConfig(
  shouldNotForwardProps(["isPlain"]),
)<StyledLinkProps>`
  ${space}
  ${layout}
  ${typography}
  ${(props) => (props.isPlain ? plainStyle : defaultStyle(props.theme))}
  ${color}
`;

const Link: React.FC<LinkProps> = ({
  to,
  disabled = false,
  children,
  plain = false,
  target = "_self",
  ...restProps
}) => {
  const isExtern =
    !to ||
    (typeof to === "string" &&
      (to.startsWith("http://") ||
        to.startsWith("https://") ||
        to.startsWith("tel:") ||
        to.startsWith("mailto:")));

  const newProps = {
    ...restProps,
    isPlain: plain,
    target,
    children,
    disabled,
    className: `${restProps.className ?? ""} hrlab-link`,
    ...(isExtern ? { href: to } : { to }),
  };

  if (disabled) {
    return <>{children}</>;
  }

  return isExtern ? (
    <NativeLink {...newProps} />
  ) : (
    // @ts-ignore TODO: investigate ts issue: prop to is only added to the props conditionally, so typescript cannot recognize that the prop existst if 'isExtern' is false
    <RouterLink {...newProps} />
  );
};

export default Link;
