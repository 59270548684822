import styled from "styled-components";
import type { BoxProps } from "Shared/flexbox";
import { Flex } from "Shared/flexbox";

export type FullFlexProps = BoxProps;

const FullHeightFlex = styled(Flex)`
  min-height: 0;
  flex: 1;
`;

export default FullHeightFlex;
