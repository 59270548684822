import React from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import ErrorPlaceholder from "Shared/Placeholder/Error";

export const withErrorBoundary = <ComponentProps extends any>(
  Component: React.ComponentType<ComponentProps>,
) => {
  class ErrorBoundary extends React.Component<
    ComponentProps
  > {

    render() {
      return (
        <Sentry.ErrorBoundary
          fallback={<ErrorPlaceholder />}
          showDialog
          onError={(error) => {
            if (process.env.NODE_ENV === "development") {
              if (error instanceof Error) {
                toast.error(error.message);
                console.error(error);
              }
            }
          }}
        >
          <Component {...this.props} />
        </Sentry.ErrorBoundary>
      ) 
    }
  }

  return ErrorBoundary;
};

export default withErrorBoundary;
