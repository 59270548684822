import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import type { ColorProps, SpaceProps } from "styled-system";
import { color, space } from "styled-system";
import { useTranslation } from "react-i18next";

import useViewport from "Shared/Viewport/use";
import viewportValues from "Shared/Viewport/values";
import { getPrivacyStatementURL } from "Shared/helpers/privacyStatementTermsOfUse";
import { Flex } from "Shared/flexbox";
import Button from "Shared/Button";
import type { SupportedLocale } from "types";

type CookieConsentBarProps = {
  onConsent: () => void;
};

type DetailsLinkProps = SpaceProps & ColorProps;

const ContainerFlex = styled(Flex)`
  background-color: rgba(37, 46, 57, 0.81);
  color: ${themeGet("colors.darkgrey")};
  font-family: ${themeGet("fonts.sans")};
  font-size: ${themeGet("fontSizes.medium")};
`;

const DetailsLink = styled.a<DetailsLinkProps>`
  color: rgb(0, 135, 197);
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
  display: block;
  ${space};
  ${color}
`;

const CookieConsentBar: React.FC<CookieConsentBarProps> = ({ onConsent }) => {
  const viewport = useViewport();
  const { i18n, t } = useTranslation();

  const isMobile = viewport === viewportValues.MOBILE;

  return (
    <ContainerFlex alignItems="center" justifyContent="center" py={2} px={2}>
      <Flex
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-evenly"
        width={1}
      >
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="flex-start"
          color="white"
          flexWrap={isMobile ? "wrap" : "nowrap"}
        >
          {t("shared.cookie")}
          <DetailsLink
            ml={isMobile ? 0 : 2}
            py={isMobile ? 2 : 0}
            href={getPrivacyStatementURL(i18n.language as SupportedLocale)}
            target="_blank"
            color="primary"
          >
            {t("shared.more-details")}
          </DetailsLink>
        </Flex>
        <Button onClick={onConsent} label="OK" />
      </Flex>
    </ContainerFlex>
  );
};

export default CookieConsentBar;
