import React from "react";
import styled from "styled-components";

import theme from "Shared/theme";
import type { ButtonProps } from "Shared/Button";
import Button from "Shared/Button";

type OptionButtonProps = ButtonProps & {
  selected?: boolean;
};

const StyledButton = styled(Button)<OptionButtonProps>`
  background-color: ${({ selected }) =>
    selected ? theme.colors.lightblue : "white"};
  font-size: ${theme.fontSizes.medium ?? "13px"};

  &:hover {
    background-color: ${theme.colors.lightblue};
  }
`;

const OptionButton: React.FC<OptionButtonProps> = ({
  selected = false,
  ...restProps
}) => (
  <StyledButton
    variant="light"
    disabled={selected}
    selected={selected}
    {...restProps}
  />
);

export default OptionButton;
