import styled from "styled-components";

import type { BoxProps } from "../Box";
import Box from "../Box";

const Flex = styled(Box)<BoxProps>({
  display: "flex",
});

export { BoxProps, Box, Flex, BoxProps as FlexProps };

export default Flex;
