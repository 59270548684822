import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "Main/Language2/locales/de.json";
import en from "Main/Language2/locales/en.json";

i18n.use(initReactI18next).init({
  resources: {
    de: {
      translation: de,
    },
    en: {
      translation: en,
    },
  },
  lng: "de",
  returnNull: false,
  interpolation: {
    defaultVariables: { itemName: "", itemName2: "" },
  },
});

export default i18n;
