import React from "react";
import type { BorderProps, GridAreaProps, MinHeightProps } from "styled-system";

import type { BoxProps } from "Shared/flexbox";
import withErrorBoundary from "Shared/hocs/withErrorBoundary";

import type { CardBodyProps } from "./CardBody";
import CardBody from "./CardBody";
import type { CardFooterProps } from "./CardFooter";
import CardFooter from "./CardFooter";
import type { CardHeaderProps } from "./CardHeader";
import CardHeader from "./CardHeader";
import type { CardContextType } from "./CardContext";
import { CardContext } from "./CardContext";
import CardPrimitive from "./CardPrimitive";

export const cardVariants = ["default", "flat", "primary", "dark", "dialogue"];

export type CardProps = Partial<CardContextType> &
  BorderProps &
  GridAreaProps &
  MinHeightProps &
  BoxProps & {
    shadow?: boolean;
    isLoading?: boolean;
    style?: React.CSSProperties;
    onClick?: () => void;
  };

const Card = withErrorBoundary<CardProps>(
  ({
    isLoading = false,
    variant = "default",
    shadow = false,
    onClick,
    ...restProps
  }: CardProps) => {
    const contextValues = {
      variant: variant,
      isLoading: isLoading,
    };

    return (
      <CardContext.Provider value={contextValues}>
        <CardPrimitive
          tabIndex={-1}
          flexDirection="column"
          variant={variant}
          $shadow={shadow}
          $hasHoverEffect={!!onClick}
          onClick={onClick}
          {...restProps}
        />
      </CardContext.Provider>
    );
  },
) as ReturnType<typeof withErrorBoundary<CardProps>> & {
  Header: React.FC<CardHeaderProps>;
  Body: React.FC<CardBodyProps>;
  Footer: React.FC<CardFooterProps>;
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
