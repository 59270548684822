import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

type ReactQueryProviderProps = {
  children: React.ReactNode;
};

// TODO: can we remove networkMode offlineFirst?
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      networkMode: "offlineFirst",
    },
    mutations: {
      networkMode: "offlineFirst",
    },
  },
});

const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;
